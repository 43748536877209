/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube, Image, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O mě"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"y6pj4judbsb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kxnnszlekus"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" columns={"2"} anim={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Poznejte mě blíže – váš realitní partner"}>
              </Title>

              <Text className="text-box" content={"Vím, že svěřit prodej své nemovitosti někomu, koho neznáte, může být náročné. Právě proto jsem vytvořil toto video. Podívejte se, jak se prezentuji, a zjistěte, kolik energie a péče věnuji každému prodeji."}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4" content={"Kontaktujte mě<br>"} use={"tel"} href={"tel:724808718"} url={"tel:724808718"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"nqGTfUAwYPc"} modestbranding={false} ivLoadPolicy={false} fs={true} controls={true} rel={"1"} autoplay={true}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50" name={"o-mne"} style={{"paddingTop":116}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1213}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":600}}>
              
              <Title className="title-box fs--48" content={"O mně"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"Po mnohaletých zkušenostech v oblasti hypoték a realit jsem se rozhodl založit vlastní společnost Häusl&amp;Home Reality s.r.o. Naší vizí je poskytovat nejlepší možný servis pro klienty, vycházející z našeho hlubokého vhledu do světa prodávajících i kupujících. Díky tomu, že jsem v mnoha případech zastupoval obě strany, rozumíme jejich potřebám a argumentům, což nám umožňuje nalézt optimální řešení."}>
              </Text>

              <Text className="text-box fs--16 w--600" style={{"maxWidth":650,"marginTop":36}} content={"Naše služby zahrnují:"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp; Upřímné a pečlivé oceňování nemovitostí"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp; Precizní prezentace nemovitostí (včetně dronových záběrů a video prezentací)"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp;&nbsp;Homestaging pro zvýšení atraktivity nemovitostí\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp;&nbsp;Kvalitní právní servis a ochrana klienta\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp; Efektivní marketing včetně&nbsp;propagace na sociálních sítích\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp; Průběžné konzultace a řešení celého procesu\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650}} content={"✔&nbsp;Pravidelné analýzy a statistiky, aby klient měl přehled o všem, co se děje"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650,"marginTop":39}} content={"Naším cílem je šetřit Váš čas a peníze a zajistit, aby koupě nebo prodej nemovitosti byl co nejjednodušší a nejefektivnější."}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":650,"marginTop":27}} content={"Celý proces průběžně konzultujeme a řešíme s Vámi, posíláme analýzy a statistiky, aby jste měli přehled o všem, co se děje. Tímto způsobem zajistíme, aby celý proces proběhl hladce a bez komplikací, s ohledem na Vaše potřeby a přání.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape4" alt={"Fotka "} src={"https://cdn.swbpg.com/t/37752/adf8d56430514e97941085754c3820ca_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":338}} srcSet={"https://cdn.swbpg.com/t/37752/adf8d56430514e97941085754c3820ca_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/adf8d56430514e97941085754c3820ca_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/adf8d56430514e97941085754c3820ca_s=860x_.png 860w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"3b8igiywvdk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}